jQuery(document).ready(function ($) {
  $(".offers-slider").slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 5000, // Adjust the speed as needed
    cssEase: "linear",
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768, // Breakpoint for mobile devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true, // Show dots only on mobile
        },
      },
      {
        breakpoint: 1024, // Breakpoint for mobile devices
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true, // Show dots only on mobile
        },
      },
    ],
  });
});
